<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.打卡详情')"
                 fixed
                 :right-text="dateDetail"
                 @click-left="back">
    </van-nav-bar>
    <div style="padding-top:46px;"
         class="selfServicePunch">
      <div>
        <p>{{ $t('tbm.打卡时间') }}</p>
        <p>{{ $t('tbm.打卡设备') }}</p>
      </div>
      <van-list v-model="loading"
                :finished="finished"
                finished-:text="$t('tbm.没有更多了')"
                @load="onLoad">
        <div v-for="(item,index) in punchTableData"
             :key="index"
             class="punchTableData">
          <div>{{item.capturedTime ? item.capturedTime.split(' ')[1] : ''}}</div>
          <van-steps direction="vertical"
                     :active="-1">
            <van-step>
              <p>{{item.deviceName}}</p>
            </van-step>
          </van-steps>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      punchTableData: [],
      dateDetail: '',
      total:0
    }
  },
  methods: {
    back () {
      this.$router.go(-1);
    },
    onLoad () {
      this.dateDetail = this.$route.query.dateDetail;
      let where = [
        {
          value: this.EhrUtils.dateToString(new Date(this.dateDetail)),
          field: 'capturedTimeFmt4',
          opt: '='
        },
      ];
      var webQuery = {
        entity: {},
        where: where,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderBy: 'capturedTime asc'
      };
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/summaryCycle/punchDetails',
        data: webQuery
      }).then(res => {
        if (res.status == 200) {
          this.loading = false;
          this.pageNum++;
          // this.punchTableData = res.data.rows;
          let dataList = res.data.rows;
          dataList.map(item => {
            this.punchTableData.push(item);
          });
          console.log("*********"+this.total);
          this.total += res.data.rows.length;
          console.log("*********"+this.total);
          console.log("*********"+res.data.total);
          if (res.data.total <= this.total) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
      this.punchDialogVisible = true
    },
  },
  created () {
  }
}
</script>
<style scoped>
.selfServicePunch > div:nth-child(1) {
  display: flex;
  align-items: center;
  height: 40px;
  background: #fff;
  color: rgb(67, 120, 190);
  position: fixed;
  width: 100%;
}
.selfServicePunch > div:nth-child(1) > p:nth-child(1) {
  width: 90px;
  text-align: center;
}
.selfServicePunch > div:nth-child(1) > p:nth-child(2) {
  padding-left: 50px;
}
.selfServicePunch > div:nth-child(2) {
  margin-top: 40px;
}
.selfServicePunch .van-list > .punchTableData {
  display: flex;
  height: 60px;
  align-items: center;
}
.selfServicePunch .van-list > .punchTableData > div:nth-child(1) {
  width: 90px;
  text-align: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
}
.selfServicePunch .van-steps {
  background-color: #eee;
}
.selfServicePunch .van-step--vertical:first-child::before {
  width: 1px;
  background-color: #919db03b;
  height: 60px;
  left: -25px;
}
</style>
<style>
.selfServicePunch .van-steps.van-steps--vertical {
  height: 100%;
  padding-left: 50px;
}
.selfServicePunch .van-steps.van-steps--vertical .van-steps__items {
  height: 100%;
}
.selfServicePunch .van-hairline.van-step.van-step--vertical {
  padding: 0 10px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.selfServicePunch .van-step__circle-container {
  top: 30px;
  left: -25px;
}
.selfServicePunch .van-step__circle {
  width: 10px;
  height: 10px;
  color: #919db0;
}
.selfServicePunch .van-step__title {
  color: rgb(51, 51, 51);
  font-size: 16px;
}
</style>

